import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
    name: 'scrnzEmojiTwitter'
})
export class ScrnzEmojiTwitterPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(codePoint): SafeHtml {
        if (codePoint === null || codePoint === '') return;
        const emojiReg = new RegExp('\^1F.*');
        const txtArray = codePoint.split(' ');
        let textMessage = '';
        for (let i = 0; i < txtArray.length; i++) {
            // if (emojiReg.test(txtArray[i])) { // emoji
            //     const emoji1 = txtArray[i].split('-').map(moji => twemoji.convert.fromCodePoint(moji)).join('');
            //     textMessage += + emoji1;
            //     continue;
            // }
            // '&nbsp'
            textMessage += ' ' + txtArray[i];
        }
        // const emojiNative = codePoint.split('-').map(moji => twemoji.convert.fromCodePoint(moji)).join('');
        return this.domSanitizer.bypassSecurityTrustHtml(twemoji.parse(codePoint, {
            folder: 'svg',
            ext: '.svg'
        }));
    }
}

declare var twemoji: {
    convert: { fromCodePoint(str: string): string; }
    parse(str: string, options?: { folder: string, ext: string }): string;
}
