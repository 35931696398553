import {SafeResourceUrl} from '@angular/platform-browser';


export enum StreamType {
	WEB_RECORDER,
	RTMP,
	EXTERNAL_STREAM,
	EXTERNAL_PLAYER
}

export interface StreamInfo {
	eventId: string;
	title: string;
	token: string;
	rtmpKey: string;
	wsUrl: string;
	watcherToken: string;
	playUrl?: SafeResourceUrl;
	rtmpUrl?: string;
	startTime: Date;
	endTime: Date;
	streamType: string;
	streamStatus: string;
	pictureUrl: string;
	playerUrl?: SafeResourceUrl;
}
