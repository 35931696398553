import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import UserCredential = firebase.auth.UserCredential;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.pug',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @Output() logged: EventEmitter<UserCredential> = new EventEmitter<UserCredential>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor(private auth: AuthService) {
    }

    ngOnInit(): void {
    }

    login(provider) {
        this.auth.login(provider).subscribe(x => this.logged.emit(x));
    }

}
