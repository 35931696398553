import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  public enableDebug = true;

  apiUrl = '';
  videoServer = '';
  antServerApp = '';
  videoServerPort = '';
  millicastAccount: string;
  rtmpUrl = '';
  playerUrl = '';
  turnUrl = '';
  cdnBaseUrl = '';
  socketServer = '';
  firebase = {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  };
  googleAnalytics = '';
  pubNubPublish = '';
  pubNubSubscribe = '';
  sentry = '';

  constructor() { }
}
