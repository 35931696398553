import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FileHandlerService} from '../../../services/file-handler.service';


@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.pug',
	styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
	@ViewChild('fileInput', {static: false}) fileInput: ElementRef;
	@Input() eventId: string;
	@Input() token: string;
	
	progress = 0;
	isUploading = false;
	currentId: string = null;
	
	
	constructor(private fileHandlerService: FileHandlerService) {
		this.fileHandlerService.progressSource.subscribe(progress => console.log('Progress: ', progress));
	}
	
	ngOnInit() {
	}
	
	onClick() {
		this.fileInput.nativeElement.click();
	}
	
	inputOnSelect(event) {
		const file = event.target.files[0];
		this.uploadImage(file.name, file);
	}
	
	uploadImage(filename: string, file: File) {
		this.isUploading = true;
		const formData = new FormData();
		formData.append('fileName', filename);
		formData.append('file', file);
		this.fileHandlerService.upload(`stream/${this.eventId}?token=${encodeURIComponent(this.token)}`, formData)
		    .subscribe(res => {
			    this.progress = 0;
			    this.isUploading = false;
			    this.currentId = res;
		    });
	}
}
