import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter, takeLast} from "rxjs/operators";

declare let ga: Function;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
    styles: []
})
export class AppComponent {
    title = 'ui-streaming-scrnz';
    defaultLanguage: string;
    constructor(translate: TranslateService, public router: Router) {
        translate.setDefaultLang('en');
        if(localStorage.getItem('currentLang')) {
            this.defaultLanguage = localStorage.getItem('currentLang');
        } else {
            this.defaultLanguage = (navigator.language === 'he') ? 'he' : 'en';
        }

        translate.use(this.defaultLanguage);
        this.router.events.pipe(
            filter(x => x instanceof NavigationEnd),
        ).subscribe((value: NavigationEnd) => {
        	if (window['ga']) {
		        ga('set', 'page', value.urlAfterRedirects);
		        ga('send', 'pageview');
	        }
        })
    }
}
