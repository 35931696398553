import {Observable} from "rxjs";
import {differenceInDays, differenceInSeconds} from "date-fns";
import {TranslateService} from "@ngx-translate/core";


export function formatDateToCountdownFunction(startTime: Date, translate: TranslateService) {
    const DAY_SECONDS =  24 * 60 * 60;
    const TWO_DAYS_SECONDS = 2*DAY_SECONDS;

    let valueSeconds = Math.max(differenceInSeconds(startTime, new Date()), 0);

    if(valueSeconds > TWO_DAYS_SECONDS) {
        return differenceInDays(startTime, new Date()) + ' ' + translate.instant('Days');
    }

    if(valueSeconds > 0) {
        const fields = [60 * 60, 60, 1];
        let secondsLeft = valueSeconds;
        let finalResult = "";

        let results: string[] = [];
        fields.forEach(x => {
            let div = Math.floor(secondsLeft / x );
            secondsLeft = secondsLeft % x;
            results.push((div < 10) ? "0" + div.toString() : div.toString());
        })

        return results.join(':');
    }

    return 'END_COUNTDOWN';
}
export function formatDateToCountdown(translate: TranslateService) {
    return function<T>(source: Observable<Date>): Observable<string> {
        return new Observable(subscriber => {
            source.subscribe({
                next(value) {
                    subscriber.next(formatDateToCountdownFunction(value, translate));
                },
                error(error) {
                    subscriber.error(error);
                },
                complete() {
                    subscriber.complete();
                }
            })
        });
    }
}
