import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ContentService} from "../../services/content.service";
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {EnvService} from '../../services/env.service';

@Component({
    selector: 'app-content-editor',
    templateUrl: './content-editor.component.pug',
    styleUrls: ['./content-editor.component.scss']
})
export class ContentEditorComponent implements OnInit {
    activeConfig: string = "Text";
    form = this.formBuilder.group({
        config: ''
    });
    currentLang: string;

    constructor(private formBuilder: FormBuilder,
                private http: HttpClient,
                public translate: TranslateService,
                private titleService: Title,
                private content: ContentService,
                private env: EnvService) {
        //Load from API
        this.currentLang = translate.currentLang;
        const url = `${this.env.apiUrl}/api/content`;

        content.getContent().subscribe(x => {
            this.form.setValue({config: x.content});
        });
        translate.onLangChange.subscribe(ev => {
            console.log('Lang change: ', ev);
            this.currentLang = ev.lang;
        });
    }


    ngOnInit(): void {
        this.titleService.setTitle('Editor')
    }

    async onSubmit(data) {
        if(!this.verifyConfigIsValidJson(data.config)) {
            return false;
        }

        //save it
        const url = `${this.env.apiUrl}/api/content/update`;
        let password = prompt('Enter password');
        let resp = await this.http.post(url, {content: data.config, password}).subscribe((x:any) => {
            if(x.success) {
                alert('Updated successfully!');
            }  else{
                alert('Something went wrong.');
            }
        });

    }

    verifyKeys(validKeys: string[], objects: []) {
        //TODO
        return true;
    }

    verifyConfigIsValidJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            alert('JSON not valid: ' + e.toString());alert('JSON not valid: ' + e.toString());
            return false;
        }

        return true;
    }
}
