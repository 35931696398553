import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EnvService} from './env.service';
export interface ContentResponse {
  content: string;
}

@Injectable({
  providedIn: 'root'
})

export class ContentService {

  constructor(private http: HttpClient, private env: EnvService) {

  }

  getContent() {
    const url = `${this.env.apiUrl}/api/content`;
    return this.http.get<ContentResponse>(url);
  }
}
