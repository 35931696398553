import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output, SimpleChanges,
	ViewChild
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter, mergeMap, retryWhen, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, of, Subject, throwError, timer} from 'rxjs';
import videojs from 'video.js';
import {HttpClient} from '@angular/common/http';
import vjsHebrew from 'video.js/dist/lang/he.json';
import vjsEnglish from 'video.js/dist/lang/en.json';
import * as Sentry from '@sentry/browser';


export enum StreamStatus {
	STREAMING_INIT = 'STREAMING_INIT',
	STREAMING = 'STREAMING',
	NOT_STREAMING = 'NOT_STREAMING'
}


@Component({
	selector: 'app-video-player',
	templateUrl: './video-player.component.pug',
	styleUrls: ['./video-player.component.scss']
})

export class VideoPlayerComponent implements AfterViewInit, OnDestroy, OnChanges {
	public $streamStatus: BehaviorSubject<StreamStatus> = new BehaviorSubject(StreamStatus.STREAMING_INIT);
	@Output() onStreamStatusChange = new EventEmitter<StreamStatus>();
	
	@ViewChild('remoteVideo') videoEl: ElementRef;
	// @Input() streamId: string;
	// @Input() token: string;
	@Input() src: string;
	@Input() isStream: boolean;
	
	$destroyed = new Subject();
	$videoDuration = new Subject<number>();
	stream: MediaStream;
	player: videojs.Player;
	// isLive = true;
	
	
	constructor(private snackBar: MatSnackBar, private http: HttpClient) {
		// noinspection TypeScriptValidateJSTypes
		videojs.log.level('all');
		this.setupLanguage();
	}
	
	ngOnChanges(changes: SimpleChanges) {
        console.log('*** Changes: ', changes);
        if (changes.isStream){
        	console.log('*** stream valid: ', changes.isStream.currentValue);
        	if(changes.isStream.currentValue) {
		        this.restartPlayer();
	        } else if(this.$streamStatus.getValue() === StreamStatus.STREAMING) {
		       console.log('**** Timeout should start here');
	        }
        }
    }
	
	ngAfterViewInit() {
		const options = {
			fluid: true,
			// autoPlay: true,
			responsive: true,
			liveui: false,
			language: 'en',
			muted: false,
			controlBar: {
				pictureInPictureToggle: false
			},
			html5: {
				vhs: {
					overrideNative: true
				},
				nativeAudioTracks: false,
				nativeVideoTracks: false
			}
		};
		import('../../../../assets/js/videojs/nuevo.min')
			.then(() => {
				this.player = videojs(this.videoEl.nativeElement, options, () => console.log('Player ready'));
				this.player.nuevo({
					relatedMenu: false,
					shareMenu: false,
					rateMenu: false,
					zoomMenu: false,
					// autoplay: true
				});
				this.player.on('ended', () => {
					console.log('player ended');
					this.updateStreamStatus(StreamStatus.NOT_STREAMING);
					if (this.isStream) {
						this.checkStream();
					} /*else {
					 this.playVod();
					 }*/
				});
				this.player.on('error', (e) => {
					console.log('player error', e);
					// this.restartPlayer();
				});
				this.player.on('waiting', () => {
					console.log('player waiting');
				});
				
				
				this.player.on('abort', () => console.log('player abort'));
				this.player.on('canplay', () => console.log('player canplay'));
				this.player.on('durationchange', () => {
					console.log('player duration change', this.player.duration());
					this.$videoDuration.next(this.player.duration());
				});
				this.player.on('emptied', () => console.log('player emptied'));
				this.player.on('enterFullWindow', () => console.log('player enter full window'));
				this.player.on('exitFullWindow', () => console.log('player exit full window'));
				this.player.on('loadeddata', () => console.log('player loaded data'));
				this.player.on('loadedmetadata', () => console.log('player loaded meta data'));
				this.player.on('stalled', () => console.log('player stalled'));
				this.player.on('suspend', () => console.log('player suspend'));
				this.checkStream();
			});
	}
	
	restartPlayer() {
		this.updateStreamStatus(StreamStatus.NOT_STREAMING);
		this.player.reset();
		this.checkStream();
	}
	
	updateStreamStatus(newStatus: StreamStatus) {
		this.$streamStatus.next(newStatus);
		this.onStreamStatusChange.emit(newStatus);
	}
	
	ngOnDestroy() {
		this.$destroyed.next(true);
	}
	
	getStream() {
		const url = this.src;
		return this.http.head(url)
		           .pipe(
			           tap(() => {
				           console.log('Got stream');
				           this.player.src({
					           src: url,
					           type: 'application/x-mpegURL'
				           });
			           }),
			           // mergeMap(() => this.$videoDuration),
			           // tap(r => console.log('After duration switch: ', r)),
			           // mergeMap(duration => duration === Infinity ? of(duration) : throwError('Bad stream')),
			           retryWhen((errors) => errors.pipe(
				           tap(err => Sentry.captureException(err)),
				           switchMap(() => {
					           this.updateStreamStatus(StreamStatus.NOT_STREAMING);
					           return timer(3000);
				           })
			           )),
		           );
	}
	
	checkStream() {
		// const url = `https://video.tv.scrnz.com:5443/scrnz/streams/${this.streamId}_adaptive.m3u8`;
		this.getStream()
		    .pipe(
			    takeUntil(this.$destroyed)
		    )
		    .subscribe({
			    next: () => {
				    // this.player.volume(0);
				    console.log('Duration: ', this.player.duration());
				    this.player.play();
				    this.player.on('play', () => {
					    console.log('Player play');
					    this.updateStreamStatus(StreamStatus.STREAMING);
					    // (document.querySelector('.unmute-btn') as HTMLElement).style.display = 'block';
				    });
			    },
			    error: e => {
				    // this.updateStreamStatus(StreamStatus.NOT_STREAMING);
				    console.error('Get stream error: ', e);
			    }
		    });
	}
	
	/*
	 playVod() {
	 console.log('Try to play VOD');
	 const url = `https://video.tv.scrnz.com:5443/scrnz/streams/${this.streamId}.mp4`;
	 this.player.autoplay(false);
	 this.player.off('ended');
	 this.player.src({
	 src: url,
	 type: 'video/mp4'
	 });
	 }
	 */
	
	setupLanguage() {
		videojs.addLanguage('he', vjsHebrew);
		videojs.addLanguage('en', vjsEnglish);
	}
	
	isIos() {
		return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	};
	
	playOnIos() {
		// this.player.currentTime()
	}
}
