import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import * as io from "socket.io-client";
import {WatchStatistics} from "../models/watch-statistics";
import {EnvService} from './env.service';

enum SOCKET_COMMANDS {
    JOIN_STATS = "JOIN_STATS"
}


enum SOCKET_EVENTS {
    UPDATE_VIEW_STATS = "UPDATE_VIEW_STATS"
}

@Injectable({
    providedIn: 'root'
})
export class ViewStatsService {
    $viewStatsUpdate: Subject<WatchStatistics> = new BehaviorSubject<WatchStatistics>({
        currentlyWatching: 0,
        maxWatching: 0,
        uniqueViewers: 0,
        vodViews: 0
    });

    socket: SocketIOClient.Socket;

    constructor(public env: EnvService) {
    }

    joinStatsUpdates(streamId: string) {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }

        this.socket = io(this.env.socketServer + '/view_stats');
        this.socket.emit(SOCKET_COMMANDS.JOIN_STATS, streamId);
        this.socket.on('reconnect', () => {
            this.socket.emit(SOCKET_COMMANDS.JOIN_STATS, streamId);
        });

        this.socket.on(SOCKET_EVENTS.UPDATE_VIEW_STATS, (msg: any) => {
            //console.log('WS ViewStats Message: ', msg);
            let newStats: WatchStatistics = {
                currentlyWatching : msg.curr,
                maxWatching: msg.max,
                uniqueViewers: msg.uniq,
                vodViews: msg.vod
            }

            this.$viewStatsUpdate.next(newStats);
        })
    }

}
