import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageDetectorService {

  constructor() { }

  isNoHebrewLetters(text: string) {
    if (!text) return true;
    const hebrew = /[\u0590-\u05F4\uFB00-\uFB4F][\u0590-\u05F4\uFB00-\uFB4F'"]*/g;
    return text.match(hebrew);
  }

}
