import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import * as Moment from 'moment';

@Pipe({
    name: 'scrnzDate'
})
export class ScrnzDatePipe implements PipeTransform {

    transform(
        value: string,
        dateType: 'day' | 'time' | 'day-month',
        locale: string): string {
        if (value.includes('Z')) {
            return this.getMomentFormat(value, dateType, locale);
        }
        const date = new Date(parseInt(value));
        const language = locale === 'he' ? 'he-il' : 'en-us';
        const format = this.setDateFormat(dateType);
        date.setDate(date.getDate());
        if (dateType === 'day-month' && locale === 'he') {
            const day = new DatePipe(language).transform(date, "d");
            const month = new DatePipe(language).transform(date, "'ב'MMMM");
            return day + ' ' + month;
        }
        return new DatePipe(language).transform(date, format);
    }

    getMomentFormat(date: string, dateType: string, locale: string) {
        if (dateType === 'day-month') {
            if (locale === 'he') {
                return Moment(date).locale(locale).format('d בMMMM');
            }
            return Moment(date).locale(locale).format('MMMM d');
        }
        if (dateType === 'day') {
            return Moment(date).locale(locale).format('dddd');
        }
        return Moment(date).locale(locale).format('HH:mm');
    }

    setDateFormat(type: string) {
        if (type === 'day') return 'EEEE';
        if (type === 'day-month') return "MMMM d";
        return 'HH:mm';
    }

}
