import {Injectable} from '@angular/core';
import {WebSocketSubject} from 'rxjs/internal-compatibility';
import {webSocket} from 'rxjs/webSocket';
import {Subject} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {WebRTCEvent} from '../../models/webrtc-event';
import {EnvService} from '../../services/env.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class MillicastService {
	ws: WebSocketSubject<any>;
	peerConnection: RTCPeerConnection;
	streamId: string;
	candidateTypes = ['udp', 'tcp'];
	iceServers: Array<any>;
	events$ = new Subject<WebRTCEvent>();
	connected = false;
	
	constructor(private env: EnvService, private http: HttpClient) {
		this.getICEServers()
		    .subscribe(servers => this.iceServers = servers);
	}
	
	publish(wsUrl, streamId, stream) {
		this.streamId = streamId;
		this.initPeerConnection();
		stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));
		this.connect(wsUrl);
	}
	
	endPublish() {
		if (this.peerConnection) {
			this.peerConnection.close();
			this.peerConnection = null;
			this.ws.complete();
			this.ws = null;
		}
	}
	
	connect(url?: string) {
		console.log(`WS Connecting to ${url}`);
		if (!this.ws) {
			this.ws = webSocket({
				url: url,
				openObserver: {next: () => this.onConnected()}
			});
			this.ws
			    .pipe(
				    retry(),
			    )
			    .subscribe({
				    next: this.onMessage.bind(this),
				    error: this.onError.bind(this),
				    complete: this.onClosed.bind(this)
			    });
		}
	}
	
	onConnected() {
		console.log('WS Connected');
		this.onStart();
		this.events$.next({event: 'initialized'});
	}
	
	async onMessage(message) {
		if (message.type === 'response') {
			await this.peerConnection.setRemoteDescription(new RTCSessionDescription({
				sdp: this.convertRemoteSdp(message.data.sdp),
				type: 'answer'
			}));
		}
	}
	
	onError(err) {
		console.error('Websocket error', err);
		this.events$.next({event: 'wserror', data: err});
	}
	
	onClosed() {
		console.log('Websocket closed');
		this.events$.next({event: 'closed'});
		this.ws = null;
		// this.connect();
	}
	
	
	initPeerConnection() {
		this.peerConnection = new RTCPeerConnection({
			iceServers: this.iceServers,
			rtcpMuxPolicy: 'require',
			bundlePolicy: 'max-bundle'
		});
		this.peerConnection.oniceconnectionstatechange = this.onIceConnectionStateChange.bind(this);
	}
	
	getICEServers() {
		return this.http.put(this.env.turnUrl, null)
		           .pipe(
			           catchError(e => {
				           console.error('Failed to get ICE servers: ', e);
				           return [];
			           }),
			           map(resp => {
				           if (resp.s !== 'ok') {
					           return [];
				           }
				           return (resp.v.iceServers || []).map(cred => {
					           if (cred.url) {
						           cred.urls = cred.url;
						           delete cred.url;
						           return cred;
					           }
				           });
			           })
		           );
	}
	
	onIceConnectionStateChange(event) {
		console.log('ice_connection_state_changed', event);
		console.log('ICE Connected status: ', this.peerConnection.iceConnectionState);
	}
	
	async onStart() {
		const config = await this.peerConnection.createOffer({offerToReceiveAudio: true, offerToReceiveVideo: true});
		await this.peerConnection.setLocalDescription(config);
		const data = {
			name: this.streamId,
			sdp: config.sdp,
			// codec: 'h264'
		};
		this.ws.next({
			type: 'cmd',
			transId: Math.random() * 10000,
			name: 'publish',
			data: data
		});
	}
	
	/**
	 * Tweak the received sdp for Millicast and to prevent old Safari errors
	 * @param sdp
	 */
	convertRemoteSdp(sdp: string) {
		// TODO: Adaptive bandwidth solution can be put here
		return sdp
			.split('\n')
			.filter(line => line.trim() !== 'a=extmap-allow-mixed')
			.join('\n')
			+ 'a=x-google-flag:conference\r\n';
	}
}
