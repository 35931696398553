import {Injectable} from '@angular/core';
import {auth} from 'firebase/app';
import 'firebase/auth';
import {from} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {first} from 'rxjs/operators';

export const fireAuthProviders = ['google', 'facebook'] as const;
export type FireProvider = (typeof fireAuthProviders)[number];

function getAuthProvider(provider: FireProvider) {
    if (provider === 'facebook') {
        return new auth.FacebookAuthProvider();
    }
    return new auth.GoogleAuthProvider();
}

@Injectable({providedIn: 'root'})
export class AuthService {

    constructor(
        private fireAuth: AngularFireAuth
    ) {
    }

    login(provider: FireProvider) {
        const authProvider = getAuthProvider(provider);
        return from(this.fireAuth.signInWithPopup(authProvider));
    }

    signOut() {
        return this.fireAuth.signOut();
    }

    getUser(): Promise<any> {
        return this.fireAuth.authState.pipe(first()).toPromise();
    }

    async isUserLogged() {
        const user = await this.getUser();
        return user !== null;
    }

}
