import {Component, Inject, OnInit} from '@angular/core';
import {LayoutComponent} from '../../layout/layout.component';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.pug',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(
      @Inject(LayoutComponent)
      private layout: LayoutComponent,
      private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Privacy');
    this.layout.isFixedOpen = false;
    this.layout.drawer.mode = 'over';

  }
  clickOpen() {
    this.layout.drawer.toggle();
  }
}
