export enum EventStatus {
    LOADING = "LOADING",
    NO_ACCESS = "NO_ACCESS",
    PREPARATION_STATE = 'PREPARATION_STATE',
    LIVE_ONAIR = 'LIVE_ONAIR',
    OFF_AIR = 'OFF_AIR'
};

interface WatchEventStatus {
    status: EventStatus
}
