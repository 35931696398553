import {BrowserModule, Title} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireAuthGuardModule} from '@angular/fire/auth-guard';
import {AngularFirestoreModule} from '@angular/fire/firestore';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RoutesModule} from './routes/routes.module';
import {SharedModule} from './shared/shared.module';
import {LayoutModule} from './layout/layout.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ConvertDateInterceptor} from "./interceptors/convert-date-interceptor.service";
import {AuthService} from './services/auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {GlobalErrorHandler} from './shared/error-handler/global-error-handler';
import {EnvServiceProvider} from './services/env.service.provider';
import {EnvironmentModel} from './models/EnvironmentModel';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const environmentGlobal = (window as any).__env as EnvironmentModel;

// noinspection AngularInvalidImportedOrDeclaredSymbol
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AngularFireModule.initializeApp(environmentGlobal.firebase),
        AngularFireAuthModule,
        AngularFireAuthGuardModule,
        AngularFirestoreModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        RoutesModule,
        SharedModule.forRoot(),
        LayoutModule,
        NgbModule,
        // AngularFireAuthGuardModule,
        // AngularFirestoreModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ConvertDateInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        Title,
        AuthService,
        EnvServiceProvider],
    bootstrap: [AppComponent]
})
export class AppModule {
}
