import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-scrnz-emoji-message',
    templateUrl: './scrnz-emoji-message.component.pug',
    styles: ['']
})
export class ScrnzEmojiMessageComponent implements OnInit {
    @Input() message: string;

    textWithEmojis = [];

    constructor() {
    }

    ngOnInit(): void {
        this.renderTextWithEmojis();
    }

    renderTextWithEmojis() {
        const messageArr = this.message.split('');
        const emojiReg = new RegExp('\^~:.*\:~$');
        for (let i = 0; i < messageArr.length; i++) {

            // text before emoji
            if (messageArr[i] === '~' && messageArr[i + 1] === ':') {
                if (messageArr[i - 1] === ' ') {
                    const text = messageArr.splice(0, i - 1);
                    this.textWithEmojis.push({type: 'text', text: text.join('')});
                    this.textWithEmojis.push({type: 'space', text: ''});
                    messageArr.shift();
                } else {
                    const text = messageArr.splice(0, i);
                    this.textWithEmojis.push({type: 'text', text: text.join('')});
                }
                messageArr.shift();
                i = 0;
            }

            // text from emoji to the end
            if (i + 1 === messageArr.length) {
                const text = messageArr.splice(0, i + 1);
                this.textWithEmojis.push({type: 'text', text: text.join('')});
                messageArr.shift();
            }

            // emoji
            if (messageArr[i] === ':' && messageArr[i + 1] === '~') {
                const emoji = messageArr.splice(0, i + 1);
                this.textWithEmojis.push({type: 'emoji', text: emoji.join('')});
                messageArr.shift();
                i = 0;
                if (messageArr[0] === ' ') {
                    this.textWithEmojis.push({type: 'space', text: ''});
                }
            }

        }
    }


}
