import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, last, map, switchMap} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import firebase from 'firebase';


@Injectable({
	providedIn: 'root'
})
export class GoogleStorageService {
	
	constructor(private httpClient: HttpClient, private afStorage: AngularFireStorage) {}
	
	getUserPhotoURL(user: firebase.User) {
		const ref = this.afStorage.ref(`pics/${user.uid}`);
		return ref.getDownloadURL()
		          .pipe(
			          catchError(e => {
				          if (e.code === 'storage/object-not-found') {
					          return this.upload(user);
				          }
			          })
		          );
	}
	
	upload(user: firebase.User) {
		const filePath = `pics/${user.uid}`;
		const fileRef = this.afStorage.ref(filePath);
		return this.httpClient.get(user.photoURL, {responseType: 'blob'})
		           .pipe(
			           map(resp => new File([resp], user.uid, {type: resp.type})),
					   switchMap(file => fileRef.put(file).snapshotChanges()),
			           last(), // Wait for upload to finish
					   switchMap(() => fileRef.updateMetadata({'cacheControl': 'private, max-age=7776000'})), // cache images for 3 months
			           switchMap(uploadResult => fileRef.getDownloadURL())
		           );
	}
	
}
