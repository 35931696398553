import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutComponent} from '../../layout/layout.component';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.pug',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  isMobileResolution = false;

  constructor(public translate: TranslateService,
              @Inject(LayoutComponent)
              private layout: LayoutComponent,
              private titleService: Title
              ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Terms');
    this.isMobileResolution = window.innerWidth < 900;
    this.layout.isFixedOpen = false;
    this.layout.drawer.mode = 'over';

  }
  clickOpen() {
    this.layout.drawer.toggle();
  }

}
