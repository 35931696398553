import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {CurrentBroadcastService} from '../../services/current-broadcast.service';
import {LanguageDetectorService} from '../../../services/language-detector.service';

@Component({
  selector: 'app-scrnz-header',
  templateUrl: './scrnz-header.component.pug',
  styleUrls: ['./scrnz-header.component.scss']
})
export class ScrnzHeaderComponent implements OnInit {
  @Input() message;
  @Input() isHideMenu;
  @Input() hideMenuIcons;
  @Output() toggleMenu = new EventEmitter();
  currentLang = '';
  isMobileResolution = false;
  isShowBackToBroadcastButton = false;
  showEventerLogo = true;

  constructor(public translate: TranslateService, router: Router, public currentBroadcastService: CurrentBroadcastService, public languageDetectorService: LanguageDetectorService) {
    this.currentLang = translate.currentLang;
    this.isMobileResolution = window.innerWidth < 900;
    translate.onLangChange.subscribe(ev => {
      this.currentLang = ev.lang;
    });
    router.events.pipe(
        filter(x => x instanceof NavigationEnd),
    ).subscribe((x: NavigationEnd) => {
      this.isShowBackToBroadcastButton = x.url === '/faq' || x.url === '/terms' || x.url === '/privacy';
      this.showEventerLogo = !(this.isMobileResolution && this.isShowBackToBroadcastButton);
    });

  }

  ngOnInit(): void {
  }

  hamburgerClicked() {
    this.toggleMenu.emit();
  }

}
