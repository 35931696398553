import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutComponent} from '../../layout/layout.component';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.pug',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
    isMobileResolution = false;
    currentLang: string;
    counter : number = 0;
    faq = [
        {
            parentName: {en: 'What is Screenz.live', he: 'מה זה סקרינז.לייב?'},
            isActive: false,
            childProperties:
                [
                    {
                        propertyName: {
                            en: 'Screenz.live is a place where you can enjoy quality video content of all types: shows, lessons, workshops, master classes and more. All you need to do is buy a ticket and enter the show. ',
                            he: 'סקרינז לייב היא המקום בו אתם יכולים להנות מתוכן וידאו איכותי מכל הסוגים. הופעות, סדנאות, כיתות אומן ועוד. כל מה שצריך זה לקנות כרטיס, ולהכנס למופע. '
                        }
                    },
                    {
                        propertyName: {
                            en: 'As creators Screenz.live provides you with a digital space you can use to teach, perform or create in. You set the price of admission and how many tickets you want to sell. You control the time and type of broadcast, and the stage is yours.',
                            he: 'כיוצרים סקרינז לייב מאפשרת לכם חלל וירטואולי ללמד, להופיע, להדריך או ליצור בו. אתם קובעים את מחיר הכרטיסים וכמה מהם ימכרו, אתם קובעים את שעת ואופי השידור, והבמה שלכם. '
                        }
                    }
                ]
        },
        {
            parentName: {en: 'How do I go on-air? ', he: 'איך משדרים?'},
            isActive: false,
            childProperties:
                [
                    {
                        propertyName: {
                            link:'eventer',
                            first:{
                                en: 'In order to broadcast on Screenz.live you need to register as creators here ',
                                he: 'על מנת לשדר בסקרינז לייב עליכם להרשם כיוצרים בלינק הזה '
                            },
                            second:{
                                en: ', and be approved by the system.',
                                he: ', ולקבל את אישור המערכת.'
                            }
                        },
                    },
                    {
                        propertyName: {
                            en: 'After you receive your confirmation e-mail, log in with your username and password, set a time for your event, the number of tickets you wish to sell, their price and your broadcasting preferences. ',
                            he: 'אחרי קבלת האישור במייל, כנסו למערכת עם שם המשתמש והסיסמא שלכם, קבעו את הזמן בו יתקיים האירוע, את כמות ומחיר הכרטיס, ואיך תרצו לשדר. '
                        }
                    },
                    {
                        propertyName: {
                            en: 'You can broadcast live using your webcam or a camera attached to your computer. ',
                            he: 'ניתן לשדר בשידור חי, דרך מצלמת רשת שמותקנת או מחוברת למחשב.'
                        }
                    },
                    {
                        propertyName: {
                            en: 'Or you can broadcast using professional software (RTMP) by copying and pasting the code from Screenz.live to your software.',
                            he: 'ניתן גם לשדר דרך תוכנת שידור מקצועית (RTMP), ע״י העתקה והדבקה של קודי השידור, מדף השידור של סקרינז אל תוך תוכנת השידור.\n'
                        }
                    }
                ]
        },
        {
            parentName: {en: 'How can I get a ticket? ', he: 'איך רוכשים כרטיסים?'},
            isActive: false,
            childProperties:
                [
                    {
                        propertyName: {
                            en: 'Tickets are purchased through Eventer and the ticket will arrive to your mailbox. Just press the link to the show and your in. ',
                            he: 'רכישת כרטיסים מתבצעת באתר איוונטר - והכרטיס יגיע ישירות אליכם למייל. בזמן היעוד לחצו על הקישור למופע ואתם בפנים. '
                        }
                    }
                ]
        },
        {
            parentName: {en: 'Can I give a ticket to someone else? ', he: 'אפשר להעביר כרטיס לחבר/ה?'},
            isActive: false,
            childProperties:
                [
                    {
                        propertyName: {
                            en: 'Sure! The link you received is unique and you can transfer it to whomever you like. But remember each link is valid for one person only. ',
                            he: 'בוודאי - הקישור שתקבלו במייל הוא כרטיס ייחודי אותו תוכלו להעביר לכל מי שתרצו. אבל זכרו, כל קישור מאפשר כניסה אחת בלבד למופע. '
                        }
                    }
                ]
        },
        {
            parentName: {en: 'How can I connect to the chat? ', he: 'איך מתחברים לצ׳אט?'},
            isActive: false,
            childProperties:
                [
                    {
                        propertyName: {
                            en: 'To connect to the chat you need to login with your Google or Facebook account. Once you’ve logged in, feel free to chat. ',
                            he: 'כדי להתחבר לצ\'אט עליכם להכנס דרך חשבון הפייסבוק או גוגל שלכם. אחרי שנכנסתם הרגישו חופשי לצ\'טט. '
                        }
                    }
                ]
        },
    ]

    constructor(public translate: TranslateService,
                @Inject(LayoutComponent)
                private layout: LayoutComponent,
                @Inject(DOCUMENT) private doc,
                private renderer: Renderer2,
                private titleService: Title
                ) {
        translate.onLangChange.subscribe(ev => {
            this.currentLang = ev.lang;
            this.closeAccordion();
            this.counter= 0;
        });
    }

    ngOnInit(): void {
        this.titleService.setTitle('FAQ');
        this.currentLang = this.translate.currentLang;
        this.isMobileResolution = window.innerWidth < 900;
        this.layout.isFixedOpen = false;
        this.layout.drawer.mode = 'over';

    }

    closeAccordion() {
        this.faq.forEach(x => x.isActive = false);
        const elements = document.getElementsByClassName('accordion');
        Array.prototype.forEach.call(elements, element => {
            let panel = element.nextElementSibling;
            do {
                panel.style.maxHeight = null;
                panel = panel.nextElementSibling;
            } while (panel != null)
        });
        this.faq = [...this.faq];
    }

    toggleAccordion(event, index) {
        const element = event.target;
        element.classList.toggle('active');
        this.faq[index].isActive = !this.faq[index].isActive;
        let panel = element.nextElementSibling;
        do {
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + 'px';
            }
            panel = panel.nextElementSibling;
        } while (panel != null);
        this.faq = [...this.faq];
        if(index===1){
            if(this.counter <1){
                let link: HTMLElement = this.renderer.createElement('a');
                link.setAttribute('href', 'https://www.eventer.co.il');
                link.setAttribute('target', '_blank');
                link.innerText = 'www.eventer.co.il';
                let textLink  = this.doc.getElementsByClassName('hasLink')[0];
                this.renderer.appendChild(textLink, link);
                ++this.counter;
            }
        }
    }

    getTranslation(textObj) {
        return textObj[this.currentLang];
    }

    clickOpen() {
        this.layout.drawer.toggle();
    }

}
