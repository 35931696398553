import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from '../layout/layout.component';
import {SharedModule} from '../shared/shared.module';
import { LivecastComponent } from './livecast/livecast.component';
import { HomeComponent } from './home/home.component';
import { WatchComponent } from './watch/watch.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { NotfoundComponent } from './notfound/notfound.component';
import {WatchComponentMobile} from './watch/watch.component.mobile';
import {ApplicationStateService} from '../services/application-state.service';
import {LivecastComponentMobile} from './livecast/livecast.component.mobile';
import { TermsComponent } from './terms/terms.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import {ScrnzDatePipe} from '../shared/pipes/scrnz-date.pipe';
import localeHebrew from '@angular/common/locales/he';
import {registerLocaleData} from '@angular/common';

registerLocaleData(localeHebrew, 'he-il');

const routes: Routes = [
    {
        path: 'live',
        component: LayoutComponent,
        children: [
/*
            {path: '', redirectTo: 'home', pathMatch: 'full'},
            {path: 'home', component: HomeComponent},
*/
            {path: 'cast/:streamId', component: LivecastComponent},
            {path: 'watch/:streamId', component: WatchComponent},
            {path: 'edit', component: ContentEditorComponent},
	        {path: '**', redirectTo: '/notfound'}
            // https://video.tv.scrnz.com/cast/5e7cb7fa7e9f8b3da94f3b6e?token=N0VTTTxkUzZXRmJE
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {path: '', redirectTo: 'home', pathMatch: 'full'},
            {path: 'home', component: FrontPageComponent},
            {path: 'faq', component: FaqComponent},
            {path: 'terms', component: TermsComponent},
            {path: 'privacy', component: PrivacyComponent}
        ]
    },
	{path: 'notfound', component: NotfoundComponent},
    {path: '**', redirectTo: 'notfound'}
];

const mobile_routes: Routes = [
    {
        path: 'live',
        component: LayoutComponent,
        children: [
            {path: '', redirectTo: 'home', pathMatch: 'full'},
            {path: 'home', component: HomeComponent},
            {path: 'cast/:streamId', component: LivecastComponentMobile},
            {path: 'watch/:streamId', component: WatchComponentMobile},
            {path: 'edit', component: ContentEditorComponent}
            // https://video.tv.scrnz.com/cast/5e7cb7fa7e9f8b3da94f3b6e?token=N0VTTTxkUzZXRmJE
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {path: '', redirectTo: 'home', pathMatch: 'full'},
            {path: 'home', component: FrontPageComponent},
            {path: 'faq', component: FaqComponent},
            {path: 'terms', component: TermsComponent},
            {path: 'privacy', component: PrivacyComponent}
        ]
    },
    {path: 'notfound', component: NotfoundComponent},
    {path: '**', redirectTo: 'notfound'}
];

@NgModule({
    declarations: [LivecastComponent,LivecastComponentMobile, HomeComponent, FrontPageComponent, ContentEditorComponent, NotfoundComponent, WatchComponentMobile, WatchComponent, TermsComponent, FaqComponent, PrivacyComponent, ScrnzDatePipe],
    imports: [
        SharedModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class RoutesModule {
    public constructor(private router: Router, private applicationStateService: ApplicationStateService) {
        if(applicationStateService.getIsMobileResolution()){
            router.resetConfig(mobile_routes);
        }
    }
}
