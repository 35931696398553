import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';

@Component({
  selector: 'app-js-script',
  templateUrl: './js-script.component.pug',
  styleUrls: ['./js-script.component.scss']
})
export class JsScriptComponent implements AfterViewInit {
  @Input() src: string;
  constructor(private element: ElementRef) {
  }

  ngAfterViewInit(): void {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.src;
    this.element.nativeElement.appendChild(script);
  }
}
