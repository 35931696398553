import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import {last, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {EnvService} from './env.service';

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {

  public progressSource = new BehaviorSubject<number>(0);

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) {
  }

  getById(route: string, id: string): Observable<any> {
    return this.httpClient.request('GET', this.env.apiUrl + '/api/' + route + '/' + id);
  }

  public upload(route: string, formData: FormData) {
    const req = new HttpRequest('POST', this.env.apiUrl + '/api/' + route, formData,
      {
        reportProgress: true
      });
    return this.httpClient.request(req).pipe(
      map(event => this.getEventMessage(event)),
      tap((envelope: any) => this.processProgress(envelope)),
      last()
    );
  }

  processProgress(envelope: any): void {
    if (typeof envelope === 'number') {
      this.progressSource.next(envelope);
    }
  }

  getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file`;
      case HttpEventType.UploadProgress:
        return Math.round((100 * event.loaded) / event.total);
      case HttpEventType.Response:
        return event.body;
      default:
        return `File  surprising upload event: ${event.type}.`;
    }
  }

  public delete(route: string, id: string) {
    this.httpClient.delete(this.env.apiUrl + '/api/' + route + '/delete/' + id).subscribe(_ => {
    });

  }

}
