import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';


@Component({
    selector: 'app-language-picker',
    templateUrl: './language-picker.component.pug',
    styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnDestroy {

    nextLang: string;
    currentLang: string;

    $destroyed = new Subject();

    constructor(public translate: TranslateService) {
        let self = this;

        function onLangChange(lang: string) {
            self.currentLang = lang;
            self.nextLang = (self.currentLang === 'he') ? 'en' : 'he';
            localStorage.setItem('currentLang', self.currentLang);
        }

        onLangChange(translate.currentLang);
        translate.onLangChange.pipe(
            takeUntil(this.$destroyed)
        ).subscribe(ev => {
            console.log('Lang change: ', ev);
            onLangChange(ev.lang);
        });
    }

    changeLang(lang) {
        this.translate.use(lang)
    }

    getLang(lang) {
        return (lang === 'he') ? 'עברית' : 'English'
    }

    ngOnDestroy() {
        this.$destroyed.next(1);
    }
}
