import {AfterViewInit, Component, HostListener, Inject, OnInit} from '@angular/core';
import {Observable, of, Subject,} from "rxjs";
import {tap, map} from "rxjs/operators"
import {ContentResponse, ContentService} from "../../services/content.service";
import {TranslateService} from '@ngx-translate/core';
import {LayoutComponent} from '../../layout/layout.component';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';

export class SliderData {
    image: string;
    desc: string;
    title: string;
    day: string;
    date: string;
    time: string;
}

export class EventData {
    image: string;
    title: string;
    location: string;
    date: string;
    isLeftToRight: boolean;
}

@Component({
    selector: 'app-front-page',
    templateUrl: './front-page.component.pug',
    styleUrls: ['./front-page.component.scss']
})

export class FrontPageComponent implements OnInit {

    content$: Observable<ContentResponse> = this.contentService.getContent().pipe(map(x => JSON.parse(x.content)));
    sliderData$: Observable<SliderData[]> = this.content$.pipe(map((x:any)=> x.slider));
    eventsData$: Observable<EventData[]> = this.content$.pipe(tap(x => {console.log('eventsData$', x)}),
        map((x:any)=> x.gallery));

    eventsDataRows$: Observable<EventData[][]> = this.eventsData$.pipe(map(events => {
        let results: EventData[][] = [];
        events.forEach((e, index) => {
            if (index % 4 == 0) {
                results.push([]);
            }

            results[results.length - 1].push(e);
        });

        return results;
    }));
    currentLang: string;
    showNavigationArrows = false;
    showNavigationIndicators = false;
    innerWidth:any;
    isDev: boolean;

    constructor(
        @Inject(LayoutComponent)
        private layout: LayoutComponent,
        private contentService: ContentService,
        public translate: TranslateService,
        config: NgbCarouselConfig,
        private http: HttpClient,
        private titleService: Title,
    ) {
        this.currentLang = translate.currentLang;
        translate.onLangChange.subscribe(ev => {
            console.log('Lang change: ', ev);
            this.currentLang = ev.lang;
            if(this.currentLang === 'en'){
                this.layout.drawer.close();
            }
        });
        config.showNavigationArrows = true;
        config.showNavigationIndicators = true;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        if(this.innerWidth < 1200){
            this.layout.drawer.close();
        } else {
            if(!this.layout.drawer.opened){
                this.layout.drawer.toggle();
            }
        }
    }

    getAsNumber(date: string) {
        return parseInt(date);
    }

    getEpochTime(timeToken: string) {
        const time = parseInt(timeToken);
        return new Date(time);
    }

    showHideControls() {
        this.showNavigationArrows = (!this.showNavigationArrows)
    }

    ngOnInit(): void {
        this.isDev = false; // switch to false to replace by coming soon page
        this.titleService.setTitle('Home');
        setTimeout(()=>{
            if(window.innerWidth>600){
                this.layout.drawer.mode = 'side';
                this.layout.drawer.toggle();
                this.layout.isFixedOpen = true;
            }
        },100)

    }

}
