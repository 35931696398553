import {TranslateService} from '@ngx-translate/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeLast} from 'rxjs/operators';


@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.pug',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
    currentLang: string;
    selectedSideButton: string = 'main';

    constructor(public translate: TranslateService, router: Router) {
        translate.onLangChange.subscribe(ev => {
            this.currentLang = ev.lang;
        });
        router.events.pipe(
            filter(x => x instanceof NavigationEnd),
        ).subscribe((x: NavigationEnd) => {
            if (x.url === '/faq') {
                this.selectedSideButton = 'faq';
            }
            if (x.url === '/terms') {
                this.selectedSideButton = 'terms';
            }
            if (x.url === '/privacy') {
                this.selectedSideButton = 'privacy';
            }

        })

    }

    @ViewChild('drawer', {static: false})
    drawer: MatSidenav;
    isFixedOpen: boolean = false;

    openSideBar() {
        if (!this.drawer.opened && !this.isFixedOpen) {
            this.drawer.open();
        }
    }

    closeSideBar() {
        if (this.drawer.opened && !this.isFixedOpen) {
            this.drawer.close();
        }
    }

    selectSideButton(button: string) {
        this.selectedSideButton = button;
    }

    isSelected(name: string) {
        if (!this.selectedSideButton) {
            return false;
        }
        return (this.selectedSideButton === name);
    }


    ngOnInit() {
    }

    ngOnDestroy() {

    }

}
