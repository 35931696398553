import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {ChatMessageToSend, ChatMessageInfo, MessageHistoryResponse} from '../../../models/ChatMessage';
import {PubNubService, PubNubUserInfo} from './pubnub.service';

export interface AudienceViewerStats {
    firebaseUID: string;
    isChattedWith: boolean;
    isCurrentlyChatting: boolean;
    amountOfUnreadMessages: number;
}

export enum SCROLL_CHANGE_TYPE {
    NEW = 'NEW',
    CHANGE = 'CHANGE'
}

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    eventId: string;
    userEventToken: string;
    chatMessagesLog: ChatMessageInfo[] = [];
    privateMessageLog: { [userToken: string]: ChatMessageInfo[] } = {};

    audienceNewPrivateMessage$ = new Subject<AudienceViewerStats>();
    checkIfScrollDownNeeded$ = new Subject<SCROLL_CHANGE_TYPE>();
    loginProcessEnded$ = new Subject();
    chatUserData: PubNubUserInfo = {displayName: '', picURL: '', firebaseUID: ''};
    privateChatUserData: PubNubUserInfo = {displayName: '', picURL: '', firebaseUID: ''};

    isPrivateChatMode = false;
    chatModeChange$ = new Subject();

    constructor(private auth: AuthService, public pubNubService: PubNubService) {
        this.pubNubService.newChatMessage$.subscribe((msg: ChatMessageInfo) => {
            this.classifyIncomingChatMessage(msg);
        })
        this.pubNubService.messageHistory$
            .pipe(
                filter(m => m.messages.length > 0)
            )
            .subscribe((msgHistory: MessageHistoryResponse) => {
                if (msgHistory.messages[0].meta.eventId === this.eventId) {
                    const msgs: ChatMessageInfo[] = msgHistory.messages.map(x => {
                        const rMessage: ChatMessageInfo = {
                            actualChannel: '@deprecated',
                            channel: this.eventId,
                            message: x.entry,
                            publisher: x.meta.from,
                            timetoken: x.timetoken.toString(),
                            userMetadata: x.meta,
                            subscription: '',
                            subscribedChannel: ''
                        }
                        return rMessage;
                    });
                    if (msgHistory.messages[0].meta.to) { // private message
                        this.organizePrivateMessages(msgs);
                    } else { // general message
                        this.chatMessagesLog = [...msgs];
                    }
                }
            })
    }

    setIsPrivateChatMode(is: boolean) {
        this.isPrivateChatMode = is;
        this.notifyChatModeChange();
    }

    setUserEventToken(token: string) {
        this.userEventToken = token;
    }

    setChatUserData(name: string, picURL: string, firebaseUID: string) {
        this.chatUserData.displayName = name;
        this.chatUserData.picURL = picURL;
        this.chatUserData.firebaseUID = firebaseUID;
        this.notifyLoginProcessEnded();
    }

    get userData() {
        return this.chatUserData
    }

    get chatUserToken() {
        return this.chatUserData.firebaseUID;
    }

    setEventId(eventId: string) {
        this.eventId = eventId;
    }

    classifyIncomingChatMessage(message: ChatMessageInfo) {
        if (message.userMetadata.to) { // private message
            this.organizePrivateMessages([message]);
            return;
        }
        this.chatMessagesLog.push(message);
    }

    organizePrivateMessages(messages: ChatMessageInfo[]) {
        for (let msg of messages) {
            const addresseeUid = msg.userMetadata.to !== this.chatUserToken ? msg.userMetadata.to : null;
            const senderUid = msg.userMetadata.from !== this.chatUserToken ? msg.userMetadata.from : null;
            const uid = addresseeUid ? addresseeUid : senderUid;

            if (!this.privateChatUserData || msg.userMetadata.from !== this.privateChatUserData.firebaseUID) {
                this.updatePrivateChatUserStats(msg, msg.userMetadata.from);
            }
            if (!this.privateMessageLog[uid]) {
                this.privateMessageLog[uid] = [msg];
                continue;
            }
            this.privateMessageLog[uid].push(msg);

        }

        const isHasUserMessage = messages.find(ownMessage => ownMessage.userMetadata.from === this.chatUserToken);
        if (isHasUserMessage) {
            const otherUser = messages.find(user => user.userMetadata.to !== this.chatUserToken);
            const audience = this.getUsersChattedWithLog();
            audience[otherUser.userMetadata.to].isChattedWith = true;
            this.setUsersChattedWithLog(audience);
        }

        if (this.privateChatUserData) {
            // if private message received from currently selected private chat user,
            // then set last received private message as read
            const receivedMessage = messages.slice().reverse().find(msg => msg.userMetadata.from === this.privateChatUserData.firebaseUID);
            if (receivedMessage) {
                // const channel = 'uid_' + this.chatUserToken + '.' + receivedMessage.userMetadata.from;
                // this.pubNubService.getPrivateMessageReceipt(channel)
                // this.pubNubService.setPrivateMessageAsRead(channel, receivedMessage.timetoken);
            }
        }

        // if (this.chatUserToken && (this.privateChatUserData && this.privateChatUserData.firebaseUID !== '')) {
        //     this.eventStatusService.updatePrivateChatLastActivityTime(this.chatUserToken, this.privateChatUserData.firebaseUID);
        // }
    }

    updatePrivateChatUserStats(message: ChatMessageInfo, uid: string) {
        let amountOfUnreadMessages = 1;
        const userStatus: AudienceViewerStats = {
            firebaseUID: uid,
            isChattedWith: true,
            isCurrentlyChatting: false,
            amountOfUnreadMessages,
        }
        this.notifyAudienceNewPrivateChatMessage(userStatus);
    }

    get privateChatAddressee() {
        return this.privateChatUserData;
    }

    get messages() {
        if (this.isPrivateChatMode) {
            return this.privateMessageLog[this.privateChatUserData.firebaseUID];
        }
        return this.chatMessagesLog;
    }

    sendMessage(message: string) {
        const chatRoom = this.isPrivateChatMode && this.privateChatUserData.firebaseUID ? `uid_${this.privateChatUserData.firebaseUID}.${this.chatUserToken}` : null;
        const msg: ChatMessageToSend = {
            displayName: this.userData.displayName,
            message,
            picURL: this.userData.picURL,
            from: this.userData.firebaseUID,
            eventId: this.eventId,
            to: this.isPrivateChatMode && this.privateChatUserData.firebaseUID ? this.privateChatUserData.firebaseUID : null
        };
        this.pubNubService.submitMessage(msg, chatRoom).then();
    }

    async selectUserToPrivateChat(user: PubNubUserInfo) {
        // if (this.chatUserToken) {
        //     if (user.firebaseUID !== '' && this.privateMessageLog[user.firebaseUID]) {
        //         const channel = 'uid_' + this.chatUserToken + '.' + user.firebaseUID;
        //         const lastReceivedMessage = this.privateMessageLog[user.firebaseUID].slice().reverse().find(msg => msg.userMetadata.from === user.firebaseUID);
        //         if (lastReceivedMessage) {
        //             // this.pubNubService.getPrivateMessageReceipt(channel)
        //             // this.pubNubService.setPrivateMessageAsRead(channel, lastReceivedMessage.timetoken);
        //         }
        //     }
        // }

        if (user.firebaseUID === '') {
            this.setIsPrivateChatMode(false);
            this.privateChatUserData = null;
            this.checkIfScrollDownNeeded(SCROLL_CHANGE_TYPE.CHANGE);
            return;
        }

        this.privateChatUserData = user;
        if (!this.privateMessageLog[user.firebaseUID] || this.privateMessageLog[user.firebaseUID].length === 0) {
            await this.pubNubService.retrievePrivateChatMessageHistory(`uid_${user.firebaseUID}.${this.chatUserToken}`, `uid_${this.chatUserToken}.${user.firebaseUID}`).then();
        }
        this.checkIfScrollDownNeeded(SCROLL_CHANGE_TYPE.CHANGE);
        this.setIsPrivateChatMode(true);
    }

    leavePrivateChat() {
        this.setIsPrivateChatMode(false);
        this.privateChatUserData = null;
        this.checkIfScrollDownNeeded(SCROLL_CHANGE_TYPE.CHANGE);
    }

    async fetchUserDataFromServer() {
        return await this.auth.getUser().then();
    }

    // PubNub

    invokeAnonymousPubNubService() {
        this.pubNubService.invokeChat(this.eventId, false).then();
    }

    invokeLoggedPubNubService() {
        this.pubNubService.invokeChat(this.eventId, true, this.userData).then();
    }

    async addPrivateChannelToPubNub() {
        const userData: PubNubUserInfo = {
            displayName: this.userData.displayName,
            picURL: this.userData.picURL,
            firebaseUID: this.userData.firebaseUID
        }
        await this.pubNubService.changeUID(userData).then();
    }

    retrieveMessageHistory(channelName: string) {
        this.pubNubService.retrieveMessageHistory(channelName).then();
    }

    resetService() {
        this.pubNubService.unSubscribeAllChannels();
        this.chatMessagesLog = [];
        this.privateMessageLog = {};
        this.chatUserData = {displayName: '', picURL: '', firebaseUID: ''};
        this.privateChatUserData = {displayName: '', picURL: '', firebaseUID: ''};
    }

    // store local log of users with which, the user has chatted before in the event

    setUsersChattedWithLog(audience: { [userToken: string]: AudienceViewerStats }) {
        const list = JSON.stringify(audience);
        localStorage.setItem(`${this.eventId}_${this.chatUserToken}`, list);
    }

    getUsersChattedWithLog() {
        const list: { [userToken: string]: AudienceViewerStats } = JSON.parse(localStorage.getItem(`${this.eventId}_${this.chatUserToken}`));
        if (!list) return;
        return list;
    }

    // emit events

    notifyAudienceNewPrivateChatMessage(userStatus: AudienceViewerStats) {
        this.audienceNewPrivateMessage$.next(userStatus);
    }

    checkIfScrollDownNeeded(chatMode: SCROLL_CHANGE_TYPE) {
        this.checkIfScrollDownNeeded$.next(chatMode);
    }

    notifyLoginProcessEnded() {
        this.loginProcessEnded$.next();
    }

    notifyChatModeChange() {
        this.chatModeChange$.next();
    }

    // storage:
    //     1. functions to convert message input to emoji

    // typeEvent(message: string, caretPosition: number) {
    //     return message.slice(0, caretPosition) + message.slice(caretPosition + 2);
    // }

    // convertMessageToString(children, data) {
    //     // if (data.message === '') return;
    //     // console.log('0000da', data)
    //     let message = data.message;
    //     const tempEmoji = data.tempEmoji;
    //     const caretPosition = data.caretPosition;
    //     const elementName = data.elementName;
    //
    //     if (children.length > 0) {
    //         // const regX = new RegExp('(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])');
    //         const emojiReg = new RegExp('\^1F.*');
    //         message = '';
    //         let prevChild = '';
    //         for (let child of children) {
    //             if (child.nodeType === Node.TEXT_NODE && !emojiReg.test(child.wholeText)) {
    //                 if (prevChild === child.wholeText) continue
    //                 prevChild = child.wholeText;
    //                 message += child.wholeText;
    //                 continue;
    //             }
    //             if (child.nodeType === Node.ELEMENT_NODE) {
    //                 // scrnzEmojiTwitter
    //                 const emoStr = ' ' + child.src.replace(/^.*[\\\/]/, '').replace('.svg', '').toUpperCase() + ' ';
    //
    //                 // scrnzEmojiCodeToEmojiImage
    //                 // const emoStr = child.title;
    //
    //
    //                 const emo = twemoji.convert.fromCodePoint(emoStr);
    //                 message += emo;
    //                 // message += '~:' + child.title + ':~';
    //             }
    //         }
    //     }
    //     if (tempEmoji !== '') {
    //         if (caretPosition === message.length) {
    //             message += tempEmoji;
    //         } else {
    //             const start = message.substring(caretPosition, 0);
    //             const end = message.substring(caretPosition + 1);
    //             message = start + ' ' + tempEmoji + ' ' + end;
    //         }
    //     }
    //
    //     return message;
    // }

    //     2. functions to set&get private message read time

    //     3. functions to get users log history from pubnub

    // this.pubNubService.usersListChange$.subscribe(() => this.getUsersChattedWithLog())
    // this.eventStatus.messagesInChat$.pipe(
    //     filter(x => x.length !== 0)
    // ).subscribe((msg) => {
    //     const reverseMsg = msg.reverse();
    //     this.chatMessagesLog = this.chatMessagesLog.concat(reverseMsg);
    // });

}
