import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentBroadcastService {
  eventType = null;
  eventId = null;
  showToken = null;

  constructor() {
  }

  setCurrentShow(event: string, streamId: string, token: string) {
    if (event) {
      this.eventType = `/live/${event}`;
      this.eventId = streamId;
      this.showToken = token;
    } else {
      this.eventType = '/live/';
      this.eventId = null;
      this.showToken = null;
    }
  }

  get eventClassification() {
    return this.eventType;
  }

  get eventIdToken() {
    return this.eventId;
  }

  get showTokenCode() {
    return this.showToken;
  }
}
