(function () {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//d2yy16lkdmfg04.cloudfront.net/resource/chat.js';
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
})();

window.embeddedChatAsyncInit = function () {
    embeddedChat.animateOnMessage = () => {};
    window.global_chatObject = embeddedChat;
}


window.global_embeddedChatLogout = function() {
    if(window.global_chatObject) {
        window.global_chatObject.logout();
    }
}

window.global_setupEmbeddedChat = function (username, imgUrl) {
    let interval;
    const imageUrl = imgUrl ? imgUrl : '';

    interval = setInterval(() => {
        let url = document.location.href;
        let channelId = url.substring(url.lastIndexOf('/')+1).split('?')[0];

        if (typeof window.global_chatObject !== 'undefined') {
            clearInterval(interval);
            window.global_chatObject.setRoom(channelId);
            if (username) {
                window.global_chatObject.setUser({"name": username, "profile_image": imageUrl});
            }
            window.global_chatObject.init("11382");
        }
    }, 200);

}
