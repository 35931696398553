import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
// import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import {MatFormFieldModule} from '@angular/material/form-field';
// import {MatButtonToggleModule} from '@angular/material/button-toggle';
// import {MatProgressBarModule} from '@angular/material/progress-bar';
// import {MatSlideToggleModule} from '@angular/material/slide-toggle';
// import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
// import {MatExpansionModule} from '@angular/material/expansion';
// import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
// import {MatMenuModule} from '@angular/material/menu';
// import {MatGridListModule} from '@angular/material/grid-list';
// import {MatSortModule} from '@angular/material/sort';
// import {MatCheckboxModule} from '@angular/material/checkbox';
// import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
// import {MatStepperModule} from '@angular/material/stepper';
// import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// import {MatRadioModule} from '@angular/material/radio';
// import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
// import {MatSliderModule} from '@angular/material/slider';
// import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';

import {VideoRecorderComponent} from './components/video-recorder/video-recorder.component';
import {JsScriptComponent} from './components/js-script/js-script.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {VideoPlayerComponent} from './components/video-player/video-player.component';
import {TranslateModule} from '@ngx-translate/core';
import { LanguagePickerComponent } from './components/language-picker.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { AudienceComponent } from './components/audience/audience.component';
import { LoginComponent } from './components/login/login.component';
import { ScrnzHeaderComponent } from './components/scrnz-header/scrnz-header.component';
import {NgbCarousel, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { ChatComponent } from './components/chat/chat/chat.component';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import { ScrnzEmojiCodeToEmojiImagePipe } from './pipes/scrnz-emoji-code-to-emoji-image.pipe';
import { ScrnzEmojiMessageComponent } from './components/scrnz-emoji-message/scrnz-emoji-message.component';
import { ScrnzEmojiTwitterPipe } from './pipes/scrnz-emoji-twitter.pipe';

@NgModule({
	declarations: [VideoRecorderComponent, JsScriptComponent, VideoPlayerComponent, LanguagePickerComponent, FileUploadComponent, AudienceComponent, LoginComponent, ScrnzHeaderComponent, ChatComponent, ScrnzEmojiCodeToEmojiImagePipe, ScrnzEmojiMessageComponent, ScrnzEmojiTwitterPipe],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		// Material Modules
		// MatAutocompleteModule,
		MatButtonModule,
		// MatButtonToggleModule,
		MatCardModule,
		// MatCheckboxModule,
		// MatChipsModule,
		// MatTableModule,
		// MatDatepickerModule,
		// MatDialogModule,
		// MatExpansionModule,
		// MatFormFieldModule,
		// MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		// MatMenuModule,
		// MatPaginatorModule,
		// MatProgressBarModule,
		// MatProgressSpinnerModule,
		// MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		// MatSlideToggleModule,
		// MatSliderModule,
		MatSnackBarModule,
		// MatSortModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatNativeDateModule,
		// MatStepperModule,
		NgbModule,
		// MatStepperModule,
		PickerModule,
		EmojiModule
	],
	exports: [
		CommonModule,
		TranslateModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		// Material Modules
		// MatAutocompleteModule,
		MatButtonModule,
		// MatButtonToggleModule,
		MatCardModule,
		// MatCheckboxModule,
		// MatChipsModule,
		// MatTableModule,
		// MatDatepickerModule,
		// MatDialogModule,
		// MatExpansionModule,
		// MatFormFieldModule,
		// MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		// MatMenuModule,
		// MatPaginatorModule,
		// MatProgressBarModule,
		// MatProgressSpinnerModule,
		// MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		// MatSlideToggleModule,
		// MatSliderModule,
		MatSnackBarModule,
		// MatSortModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatNativeDateModule,
		// MatStepperModule,
		VideoRecorderComponent,
		JsScriptComponent,
		VideoPlayerComponent,
		LanguagePickerComponent,
		FileUploadComponent,
		AudienceComponent,
		LoginComponent,
		ScrnzHeaderComponent,
		NgbModule,
		ScrnzHeaderComponent,
		LoginComponent,
		ChatComponent,
		PickerModule,
		EmojiModule,
		ScrnzEmojiCodeToEmojiImagePipe,
		ScrnzEmojiMessageComponent
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule
		};
	}
}
