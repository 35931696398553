import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {EventStatusService} from '../../services/event-status.service';
import {ViewStatsService} from '../../services/view-stats.service';
import {LivecastComponent} from './livecast.component';
import {CurrentBroadcastService} from '../../shared/services/current-broadcast.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {LanguageDetectorService} from '../../services/language-detector.service';
import {EnvService} from '../../services/env.service';


@Component({
    selector: 'app-livecast-mobile',
    templateUrl: './livecast.component.mobile.pug',
    styleUrls: ['./livecast.component.mobile.scss'],
	// TODO: Change the changeDetection to onPush to improve performance
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class LivecastComponentMobile extends LivecastComponent{
    constructor(
        public route: ActivatedRoute,
        public http: HttpClient,
        router: Router,
        public sanitizer: DomSanitizer,
        public translate: TranslateService,
        public eventStatusService: EventStatusService,
        public viewStatsServer: ViewStatsService,
        public titleService: Title,
        public currentBroadcastService: CurrentBroadcastService,
        public languageDetectorService: LanguageDetectorService,
        public env: EnvService
    )
    {
        super(route, http, router, sanitizer, translate, eventStatusService, viewStatsServer, titleService, currentBroadcastService, languageDetectorService, env)
    }
}
