import {Inject, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {emojis, EmojiService, CompressedEmojiData} from "@ctrl/ngx-emoji-mart/ngx-emoji";
import {DOCUMENT} from "@angular/common";

// https://github.com/TypeCtrl/ngx-emoji-mart/issues/89

@Pipe({
    name: 'scrnzEmojiCodeToEmojiImage'
})
export class ScrnzEmojiCodeToEmojiImagePipe implements PipeTransform {
    private static cachedEmojiRegex: RegExp;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private sanitizer: DomSanitizer,
        private emojiService: EmojiService
    ) {
    }

    transform(html: string,
              set: '' | 'apple' | 'facebook' | 'twitter' | 'google' = 'apple',
              size?: number,
              sheetSize?: 16 | 20 | 32 | 64,
              backgroundImageFn?: (set: string, sheetSize: number) => string,
              sheetRows?: number,
              sheetColumns?: number): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(
            this.emojisToImages(
                html,
                set,
                size,
                sheetSize,
                backgroundImageFn,
                sheetRows,
                sheetColumns
            )
        );
    }

    public static getAllTextNodes(node: Node) {
        const all = [];
        for (node = node.firstChild; node; node = node.nextSibling) {
            if (node.nodeType === Node.TEXT_NODE) {
                all.push(node);
            } else {
                all.push(...ScrnzEmojiCodeToEmojiImagePipe.getAllTextNodes(node));
            }
        }
        return all;
    }

    public emojisToImages(
        html: string,
        set?: '' | 'apple' | 'facebook' | 'twitter' | 'google',
        size?: number,
        sheetSize?: 16 | 20 | 32 | 64,
        backgroundImageFn?: (set: string, sheetSize: number) => string,
        sheetRows?: number,
        sheetColumns?: number
    ): string {
        // Ensure most html entities are parsed to unicode:
        const div = <Element>this.document.createElement('div');
        div.innerHTML = html;

        const textNodes = ScrnzEmojiCodeToEmojiImagePipe.getAllTextNodes(div);
        for (let currentItem of textNodes) {
            let match: RegExpExecArray;
            while ((match = this.emojiRegex.exec(currentItem.textContent)) !== null) {
                const unicodeEmoji = currentItem.textContent.substr(match.index, match[0].length);
                const hexCodeSegments = [];
                let j = 0;
                while (j < unicodeEmoji.length) {
                    const segment = unicodeEmoji.codePointAt(j).toString(16).toUpperCase();
                    hexCodeSegments.push(segment);

                    j += Math.ceil(segment.length / 4);
                }
                const hexCode = hexCodeSegments.join('-');
                const matchingData = this.findEmojiData(hexCode);
                if (matchingData) {
                    const span = document.createElement('span');
                    span.setAttribute('contenteditable', 'false');
                    span.title = matchingData.unified;
                    // span.title = matchingData.shortName; // 000 ido: identify emojis span
                    span.className = 'emoji-pipe-image';

                    const styles = this.emojiService.emojiSpriteStyles(
                        matchingData.sheet,
                        set,
                        size,
                        sheetSize,
                        sheetRows,
                        backgroundImageFn,
                        sheetColumns
                    );
                    Object.assign(span.style, styles);

                    const text = currentItem.textContent;
                    currentItem.textContent = text.substr(0, match.index);
                    currentItem.parentNode.insertBefore(span, currentItem.nextSibling);
                    currentItem = this.document.createTextNode(text.substr(match.index + match[0].length));
                    span.parentNode.insertBefore(currentItem, span.nextSibling);

                    this.emojiRegex.lastIndex = 0;
                }
            }
        }

        return div.innerHTML;
    }

    private get emojiRegex(): RegExp {
        if (ScrnzEmojiCodeToEmojiImagePipe.cachedEmojiRegex) {
            return ScrnzEmojiCodeToEmojiImagePipe.cachedEmojiRegex;
        }

        let characterRegexStrings: string[] = [];
        for (const emoji of emojis) {
            characterRegexStrings.push(this.emojiService.unifiedToNative(emoji.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

            if (emoji.skinVariations) {
                for (const skinVariation of emoji.skinVariations) {
                    characterRegexStrings.push(this.emojiService.unifiedToNative(skinVariation.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
                }
            }
        }

        characterRegexStrings = characterRegexStrings.sort((a, b) => {
            if (a.length > b.length) {
                return -1;
            }

            if (b.length > a.length) {
                return 1;
            }

            return 0;
        });

        const strings = characterRegexStrings;
        const reString = '(' + strings.join('|') + ')';
        ScrnzEmojiCodeToEmojiImagePipe.cachedEmojiRegex = new RegExp(reString, 'gu');

        return ScrnzEmojiCodeToEmojiImagePipe.cachedEmojiRegex;
    }

    private findEmojiData(hexCode: string): CompressedEmojiData {
        for (const emojiData of emojis) {
            if (emojiData.unified === hexCode) {
                return emojiData;
            }

            if (emojiData.skinVariations) {
                for (const skinVariation of emojiData.skinVariations) {
                    if (skinVariation.unified === hexCode) {
                        const skinData = Object.assign({}, emojiData);
                        skinData.sheet = skinVariation.sheet;
                        return skinData;
                    }
                }
            }
        }

        return null;
    }

}
